<template>
  <div>
    <div>
      <div
        v-if="$route.name !== 'edit-device' && $route.name !== 'view-device'"
      >
        <form-wizard
          ref="formWizard"
          color="#7367F0"
          :title="null"
          :subtitle="null"
          shape="square"
          :finish-button-text="$t('device.GoToAllDevices')"
          :next-button-text="nextBtn"
          :back-button-text="$t('device.previous')"
          v-model="activeTab"
          :startIndex="startIndex"
          @on-change="changeNxtBtn"
          @on-next="changeNxtBtn1"
          :class="nextDisabled ? 'disabled-next  mb-3' : 'mb-3'"
          @on-complete="redirectOnDeviceHome"
        >
          <!-- unit creation  tab -->
          <tab-content
            :title="$t('device.General')"
            :before-change="createDevice"
          >
            <validation-observer ref="unitRules" tag="form">
              <DeviceCreate
                :deviceFun="deviceFun"
                :deviceData="device"
                :isDiscardDevice="isReset"
                :updateModel="updateModel"
                :oldManufacturerData="manufacturerIdOld"
                :activeTab="activeTab"
                :getAllAccountOp="getAllAccountOptions"
              ></DeviceCreate>
            </validation-observer>
          </tab-content>
          <!-- Filters tab -->

          <!-- unit allocation tab -->
          <tab-content :title="$t('device.Sensors')">
            <validation-observer ref="infoRules" tag="form">
              <DeviceSensor
                :deviceData="device"
                :activeTripDetector="activeTripDetector"
                :activeFuelTheft="activeFuelTheft"
                :isViewOnly="isViewOnly"
                :DeviceSensorFun="DeviceSensorFun"
                :saveSensors="saveSensors"
                :refreshSensorsModal="refreshSensorsModal"
                :protocolName="protocolName"
                unit=""
                :isEditable="isEditable"
                :activeTab="activeTab"
              ></DeviceSensor>
            </validation-observer>
          </tab-content>

          <!-- address  -->
          <tab-content
            :title="$t('device.Trip Detector')"
            class="no-header-modal"
            v-if="isTripDetector"
            :before-change="saveTripDetector"
          >
            <validation-observer ref="unitRules5" tag="form">
              <AddDeviceTripDetector
                :tripDetectorPropFun="tripDetectorPropFun"
                :deviceData="device"
                :saveTripDetector="saveTripDetector"
                :activeTab="activeTab"
                :isViewOnly="isViewOnly"
              ></AddDeviceTripDetector>
            </validation-observer>
          </tab-content>

          <tab-content
            :title="$t('device.filters.filters')"
            :before-change="saveFilter"
            v-if="isTripDetector"
          >
            <validation-observer ref="unitRules7" tag="form">
              <filters
                :activeTab="activeTab"
                :protocolName="protocolName"
                :isViewOnly="isViewOnly"
                :saveFiltersData="saveFiltersData"
                :device="device"
                :filtersData="filtersData"
                :activeTabTitle="
                  $refs.formWizard &&
                  $refs.formWizard.tabs &&
                  $refs.formWizard.tabs.length
                    ? $refs.formWizard.tabs[activeTab].title
                    : ''
                "
              ></filters>
            </validation-observer>
          </tab-content>
          <tab-content
            :title="$t('device.fuelTheft.FuelConfig')"
            class="no-header-modal"
            v-if="isFuelTheft"
            :before-change="saveFuelTheft"
          >
            <validation-observer ref="deviceFuelTheft" tag="form">
              <FuelTheft
                :FuelTheftPropFun="FuelTheftPropFun"
                :deviceData="device"
                :saveFuelTheft="saveFuelTheft"
                :activeTab="activeTab"
                :isViewOnly="isViewOnly"
              ></FuelTheft>
            </validation-observer>
          </tab-content>
          <tab-content
            v-if="isNewTabs"
            :title="$t('device.Commands')"
            class="no-header-modal"
            :before-change="saveCommands"
          >
            <DeviceSensorCommand
              :deviceData="device"
              :DeviceCommandFun="DeviceCommandFun"
              :saveCommands="saveCommands"
              :isViewOnly="isViewOnly"
              :activeTabTitle="
                $refs.formWizard &&
                $refs.formWizard.tabs &&
                $refs.formWizard.tabs.length
                  ? $refs.formWizard.tabs[activeTab].title
                  : ''
              "
              :activeTab="activeTab"
            ></DeviceSensorCommand>
          </tab-content>

          <tab-content
            v-if="isNewTabs"
            :title="$t('device.Ready')"
            class="no-header-modal"
          >
            <DeviceConnectionStatus
              okButtonLabel="Proceed to create unit"
              :cancelButtonLabel="$t('device.skipNow')"
              :device_id="deviceId"
              :protocol_info="protocol_info"
              @onOk="redirectOnUnitCreation"
              @onCancel="redirectOnDeviceHome"
            />
          </tab-content>

          <template
            slot="custom-buttons-right"
            v-if="currentActivePage === `${$t('device.Commands')}`"
          >
          </template>
        </form-wizard>
      </div>
      <div>
        <div
          v-if="$route.name === 'edit-device' || $route.name === 'view-device'"
        >
          <b-tabs
            active-nav-item-class="font-weight-bold "
            v-model="activeTab"
            content-class=""
            @activate-tab="handleTabChange"
          >
            <b-tab :title="$t('device.General')" class="custom-tab">
              <validation-observer ref="unitRules" tag="form">
                <b-card class="mt-36">
                  <DeviceCreate
                    :deviceFun="deviceFun"
                    :deviceData="device"
                    :updateModel="updateModel"
                    :isDiscardDevice="isReset"
                    :oldManufacturerData="manufacturerIdOld"
                    :activeTab="activeTab"
                    :getAllAccountOp="getAllAccountOptions"
                  ></DeviceCreate>

                  <b-row>
                    <b-col cols="12">
                      <div class="d-flex allocation-wrap justify-content-end">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          class="mt-2 mr-1"
                          type="button"
                          v-if="isEditable && !isViewOnly"
                          @click="createDevice()"
                        >
                          {{ $t("device.Save") }}
                        </b-button>

                        <DeviceAllocation
                          :deviceData="device"
                          :getAllAccountOp="getAllAccountOptions"
                          :isViewOnly="isViewOnly"
                          v-if="
                            checkAbility({
                              action: constants.PERMISSIONS_ACTION.UPDATE,
                              subject: constants.PERMISSIONS_MODEL.DEVICES
                            }) &&
                            allocationFlag &&
                            ($route.name === 'edit-device' ||
                              $route.name === 'view-device')
                          "
                        />

                        <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                          class="mt-2 mr-1"
                          @click="resetForm"
                          v-if="isEditable && !isViewOnly"
                        >
                          {{ $t("device.Discard") }}
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </b-card>
              </validation-observer>
            </b-tab>
            <b-tab :title="$t('device.History')">
              <DeviceHistory
                component_key="device-edit"
                :deviceData="device"
              ></DeviceHistory
            ></b-tab>

            <b-tab :title="$t('device.Sensors')">
              <b-card class="card-body-p">
                <DeviceSensor
                  :deviceData="device"
                  :activeTripDetector="activeTripDetector"
                  :activeFuelTheft="activeFuelTheft"
                  :DeviceSensorFun="DeviceSensorFun"
                  :isViewOnly="isViewOnly"
                  :saveSensors="saveSensors"
                  :refreshSensorsModal="refreshSensorsModal"
                  :protocolName="protocolName"
                  unit=""
                  :activeTab="activeTab"
                ></DeviceSensor>
              </b-card>
            </b-tab>
            <b-tab :title="$t('device.Trip Detector')" v-if="isTripDetector">
              <b-card class="card-body-p">
                <AddDeviceTripDetector
                  :deviceData="device"
                  :tripDetectorPropFun="tripDetectorPropFun"
                  :saveTripDetector="saveTripDetector"
                  :isViewOnly="isViewOnly"
                ></AddDeviceTripDetector>
              </b-card>
            </b-tab>
            <b-tab :title="$t('device.filters.filters')" v-if="isTripDetector">
              <b-card class="">
                <filters
                  :activeTab="activeTab"
                  :protocolName="protocolName"
                  :isViewOnly="isViewOnly"
                  :saveFiltersData="saveFiltersData"
                  :device="device"
                  :saveFilter="saveFilter"
                ></filters>
              </b-card>
            </b-tab>
            <b-tab
              :title="$t('device.fuelTheft.FuelConfig')"
              v-if="isFuelTheft"
            >
              <b-card class="">
                <FuelTheft
                  :deviceData="device"
                  :FuelTheftPropFun="FuelTheftPropFun"
                  :saveFuelTheft="saveFuelTheft"
                  :isViewOnly="isViewOnly"
                ></FuelTheft>
              </b-card>
            </b-tab>
            <b-tab :title="$t('device.Commands')">
              <b-card class="card-body-p">
                <DeviceSensorCommand
                  :deviceData="device"
                  :DeviceCommandFun="DeviceCommandFun"
                  :saveCommands="saveCommands"
                  :activeTab="activeTab"
                  :isViewOnly="isViewOnly"
                ></DeviceSensorCommand>
              </b-card>
            </b-tab>
          </b-tabs>
        </div>
      </div>
      <AreYouSureModal
        :data="{
          ...device,
          modelName: 'modal-sm-update-model',
          name: ''
        }"
        :onClose="onClose"
        :removedUser="createDeviceData"
        :customData="customData"
      />
      <Loader :show="showLoading" />
    </div>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { required } from "@validations";
import vSelect from "vue-select";
import Loader from "@/layouts/components/Loader.vue";
import Ripple from "vue-ripple-directive";
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import formValidation from "@core/comp-functions/forms/form-validation";
import AccountService from "@/libs/api/account-service";
import DeviceService from "@/libs/api/device-service";
import DataService from "@/libs/api/data-service";
import UnitService from "@/libs/api/unit-service";
import DeviceCreate from "./DeviceCreate.vue";
import DeviceHistory from "./DeviceHistory.vue";
import constants from "@/utils/constants";
import DeviceAllocation from "./DeviceAllocation.vue";
import DeviceSensor from "./DeviceSensor.vue";
import DeviceSensorCommand from "./DeviceSensorCommand";
import AddDeviceTripDetector from "./AddDeviceTripDetector.vue";
import DeviceConnectionStatus from "./DeviceConnectionStatus.vue";
import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import FuelTheft from "./fuelTheft.vue";
import filters from "./filters.vue";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BMedia,
  BFormInvalidFeedback,
  BFormFile,
  BImg,
  BAvatar,
  BButton,
  BOverlay,
  BForm,
  BTab,
  BTabs,
  BCard
} from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    vSelect,
    BOverlay,
    BMedia,
    BCol,
    BAvatar,
    BButton,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormFile,
    FuelTheft,
    BImg,
    BForm,
    BCardCode,
    DeviceCreate,
    BTab,
    BTabs,
    DeviceHistory,
    DeviceAllocation,
    DeviceSensor,
    DeviceSensorCommand,
    DeviceConnectionStatus,
    AddDeviceTripDetector,
    BCard,
    Loader,
    AreYouSureModal,
    filters
  },
  data() {
    return {
      required,
      roleData: {},
      allPermissions: {},
      labels: {},
      show: false,
      isAddRole: false,
      activeTab: 0,
      isNewTabs: this.$route.name === "add-new-device" ? true : false,
      protocolName: "",
      nextBtn: this.$t("saveAndConfigure"),
      hasErr: "",
      customData: {},
      modelText: null,
      device: {
        isDeviceEditable: false,
        timezone: JSON.parse(localStorage.getItem("USER_PROFILE_DETAILS"))
          ? JSON.parse(localStorage.getItem("USER_PROFILE_DETAILS")).timezone
          : "UTC",
        deviceName: null,
        version: "",
        SIMNumber1: null,
        SIMOperator1: null,
        SIMNumber2: null,
        SIMOperator2: null,
        model: null,
        status: "ACTIVE",
        uniqueIdentifier: null,
        account: null,
        sensors: [],
        labels: [],
        config: {},
        commands: []
      },
      manufacturerId: "",
      isReset: false,
      manufacturerIdOld: "",
      modelId: "",
      prevRoute: null,
      manufacturers: [],
      oldDeviceOwnerId: null,
      deviceOwnerId: null,
      filtersData: [],
      showLoading: false,
      currentActivePage: "",
      updateDeviceModel: {},
      isUpdateCall: false,
      allocationFlag: false,
      isTabClick: false,
      uniqueHandlError: false,
      deviceId: "",
      initialDevice: null,
      profileFile: null,
      statusOption: ["ACTIVE", "INACTIVE", "DAMAGED", "BLOCKED"],
      accountOptions: [],
      getAllAccountOptions: [],
      allocationHistory: [],
      singleHistory: [],
      isFormTab: false,
      modelOptions: [],
      required,
      constants,
      isTripDetector: false,
      isFuelTheft: false,
      fuelTheftData: {},
      fuelRefillData: {},

      isDeviceReallocation: false,
      isEditable: true,
      tripDetector: {},
      startDate: null,
      endDate: null,
      currentPage: 1,
      totalDevices: null,
      perPage: 8,
      allocationData: null,
      DeviceCommandsData: [],
      c1: null,
      status: false,
      filterAccount: "",
      previousEndDate: null,
      previousStartDate: null,
      identifierError: false,
      simCartError: false,
      isViewOnly: false,
      startIndex: 0,
      DeviceSensorData: [],
      refreshSensorsModal: "",
      OldDeviceData: {},
      nextDisabled: true,
      protocol_info: {
        port: "",
        protocol: "",
        server_ip: ""
      }
    };
  },
  directives: {
    Ripple
  },
  created() {
    // Retrieve the active tab index from local storage
    if (
      this.$route.name === "edit-device" ||
      this.$route.name === "view-device"
    ) {
      this.isFormTab = true;
      this.activeTab =
        this.$route.query &&
        (Number(this.$route.query.tab) || Number(this.$route.query.tab) == 0)
          ? Number(this.$route.query.tab)
          : 0;
    }
  },
  beforeDestroy() {
    removeEventListener("click", (event) => {});
  },
  mounted() {
    this.getChildAccounts();
    if (this.$refs && this.$refs.formWizard) {
      const nextButton = this.$refs.formWizard.$el.querySelector(".wizard-btn");
      setTimeout(() => {
        const tabs = this.$refs.formWizard.$el.querySelectorAll(
          ".wizard-nav-pills li a"
        );
        tabs.forEach((tab) =>
          tab.addEventListener("click", this.handleTabSwitch)
        );
      }, 100);

      // Attaching the event listeners
      nextButton.addEventListener("click", this.handleNextClick);
    }
    if (
      this.$route.name === "edit-device" ||
      this.$route.name === "view-device" ||
      this.$route.name === "add-new-device-id"
    ) {
      if (this.$route.params.id) {
        this.getOneDevice(this.$route.params.id);
        const nextIndex =
          this.$route.query &&
          (Number(this.$route.query.tab) || Number(this.$route.query.tab) == 0)
            ? Number(this.$route.query.tab)
            : 0;
        if (this.$refs.formWizard) {
          this.$refs.formWizard.changeTab(this.activeTab || 0, nextIndex);
        }
        if (this.isFormTab) {
          this.activeTab = nextIndex;
        }
      } else {
        this.$router.go(-1);
      }
    }
    if (
      this.$route.name === "edit-device" ||
      this.$route.name === "view-device"
    ) {
      this.isFormTab = true;
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  watch: {
    device(newVal, oldval) {
      this.deviceData = newVal;
    },
    $route(to, from) {
      const nextIndex =
        this.$route.query &&
        (Number(this.$route.query.tab) || Number(this.$route.query.tab) == 0)
          ? Number(this.$route.query.tab)
          : 0;
      if (this.$refs.formWizard) {
        this.$refs.formWizard.changeTab(this.activeTab || 0, nextIndex);
      }

      if (Number(this.$route.query.tab) == -1) {
        this.$router.replace({ query: { tab: 0 } }).catch((e) => {});
      }
      if (
        this.$route.name === "add-new-device-id" &&
        this.$route.query.tab == 1
      ) {
        this.nextDisabled = false;
      }
    }
  },
  methods: {
    isDisabled() {
      if (
        (this.$route.name === "add-new-device" ||
          (this.$route.name === "add-new-device-id" &&
            this.$route.query.tab == 0)) &&
        this.device.deviceName &&
        this.device.deviceName.length >= 3 &&
        this.device.SIMNumber1 &&
        this.device.SIMNumber1.toString().length >= 6 &&
        this.device.SIMOperator1 &&
        this.device.SIMOperator1.length >= 2 &&
        this.device.model &&
        this.device.status &&
        this.device.uniqueIdentifier &&
        this.device.uniqueIdentifier.length >= 2 &&
        this.device.timezone
      ) {
        this.nextDisabled = false;
      } else {
        this.nextDisabled = true;
      }
    },
    async validateRolePermission() {},
    handleNextClick() {
      this.isTabClick = false;
    },
    handleTabSwitch() {
      this.isTabClick = true;
    },
    async validationFormFilter() {
      return new Promise((resolve, reject) => {
        this.$refs.unitRules7.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    async validationFormTripDetector() {
      return new Promise((resolve, reject) => {
        this.$refs.unitRules5.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    async validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.unitRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    async deviceFuelTheftValidationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.deviceFuelTheft.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    searchAccount(filterAccount) {
      this.getChildAccounts(filterAccount);
    },

    goBack() {
      this.$router.go(-1);
    },

    resetForm() {
      this.device = Object.assign({}, this.OldDeviceData);

      this.isReset = true;
    },
    async parserModelData(data) {
      this.showLoading = true;
      const response = await new DeviceService().parserModelData({
        model_id: data.id
      });
      this.showLoading = false;
      if (response && response.data) {
        // const parserModel = {
        //   port: response.data.port,
        //   protocol: response.data.protocol,
        //   server_ip: response.data.public_ip
        // };
        // this.protocol_info = JSON.parse(JSON.stringify(parserModel));
        this.protocol_info.port = response.data.port;
        this.protocol_info.protocol = response.data.protocol;
        this.protocol_info.server_ip = response.data.public_ip;
      } else if (response && response.error && response.error.message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error.title,
            text: response.error.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    findLoops(key, graph, target, isNodeVisited) {
      if (!graph[key].length || isNodeVisited.has(key))
        return { success: false, node: null };
      isNodeVisited.add(key);
      for (let value of graph[key]) {
        if (value === target) {
          return { success: true, node: key };
        }
        const result = this.findLoops(value, graph, target, isNodeVisited);
        if (result.success) {
          return result;
        }
      }
      return { success: false, node: null };
    },
    createGraph(data) {
      const sensors = data || [];
      const newSensors = JSON.parse(JSON.stringify(sensors));
      const graph = {};
      // buildGraph(sensors, graph, newSensors);
      // created Graph
      for (let sensor of sensors) {
        let sensorIndex = newSensors.findIndex(
          (s) => this.trimName(s.name) === this.trimName(sensor.name)
        );
        const name = this.trimName(sensor.name);
        if (!(name in graph)) {
          graph[name] = [];
        }
        for (let key of Object.keys(sensor.fields)) {
          const field = sensor.fields[key];
          const regex = /\[(.*?)\]/g;
          let match;
          while ((match = regex.exec(field.value || "")) !== null) {
            const parameter = match[1].split("#");
            const sensorName = this.trimName(parameter[0]);
            graph[name].push(sensorName);
            const findParameterIndex = newSensors.findIndex(
              (sensor) => this.trimName(sensor.name) === sensorName
            );
            if (findParameterIndex > sensorIndex) {
              const temp = newSensors[findParameterIndex];
              newSensors[findParameterIndex] = newSensors[sensorIndex];
              newSensors[sensorIndex] = temp;
              sensorIndex = findParameterIndex;
            }
          }
        }
      }
      const isNodeVisited = new Set();
      for (let key of Object.keys(graph)) {
        const result = this.findLoops(key, graph, key, isNodeVisited);
        if (result.success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${key} sensors loop found with ${result.node}`,
              text: "",
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          this.showLoading = false;
          this.resetForm();
          this.isReset = true;

          return false;
        }
      }
      return true;
    },
    trimName(name) {
      return name.trim();
    },
    wrapValuesInBraces(obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          if (value && typeof value === "object") {
            this.wrapValuesInBraces(value);
          } else if (
            key === "value" &&
            obj.source === "input" &&
            value &&
            typeof value === "string" &&
            !(value.startsWith("{") && value.endsWith("}"))
          ) {
            obj[key] = `{${value}}`;
          }
        }
      }
    },

    transformArray(arr) {
      return arr.map((item) => {
        const sensor = item;
        if (sensor) {
          // this.wrapValuesInBraces(sensor.fields);
        }
        return item;
      });
    },
    async getOneDevice(id) {
      try {
        if (!id) {
          return true;
        }
        this.showLoading = true;
        let response = await new DeviceService().getOneDevice({ id });
        this.showLoading = false;
        if (response && response.data) {
          this.manufacturerId = response.data.manufacturer_id;
          this.manufacturerIdOld = response.data.manufacturer_id;
          let simCards =
            response.data.sim_cards &&
            response.data.sim_cards._arr &&
            response.data.sim_cards._arr.length
              ? response.data.sim_cards._arr
              : [];
          // await this.parserModelData({ id: response.data.model_id });
          this.protocol_info.port = response.data.protocol_info.port;
          this.protocol_info.protocol = response.data.protocol_info.protocol;
          this.protocol_info.server_ip = response.data.protocol_info.public_ip;
          this.device = {
            isDeviceEditable: response.data.isDeviceEditable,
            timezone: response.data.timezone || "UTC",
            account: response.data.account,
            deviceId: id,
            manufacturerId: response.data.manufacturer_id,
            deviceName: response.data.device_name,
            version: response.data.version,
            labels: response.data.labels,
            SIMNumber1:
              (simCards && simCards.length && simCards[0].sim_number) || "",
            SIMOperator1:
              (simCards && simCards.length && simCards[0].sim_operator) || "",
            SIMNumber2:
              (simCards && simCards.length >= 2 && simCards[1].sim_number) ||
              "",
            SIMOperator2:
              (simCards && simCards.length >= 2 && simCards[1].sim_operator) ||
              "",
            model: response.data.model_id,
            status: response.data.status,
            uniqueIdentifier: response.data.identifier,

            // account: response.data.owner_account_id,
            sensors:
              response.data.sensors && response.data.sensors.length
                ? this.transformArray(response.data.sensors)
                : [],
            config: response.data.config,
            commands:
              response.data.commands && response.data.commands.length
                ? response.data.commands
                : [],

            default_protocol: response.data.default_protocol,
            id,
            port: response.data.protocol_info.port,
            protocol: response.data.protocol_info.protocol,
            server_ip: response.data.protocol_info.public_ip,
            password: response.data.password
          };
          this.protocolName = this.device.default_protocol;
          this.DeviceSensorData = this.device.sensors;
          this.DeviceCommandsData = this.device.commands;
          this.tripDetector =
            this.device.config && this.device.config.trip_detector;
          this.fuelTheftData =
            this.device.config && this.device.config.fuel_theft;
          this.fuelRefillData =
            this.device.config && this.device.config.fuel_refill;
          this.refreshSensorsModal = new Date() + "";
          this.deviceData = this.device;
          this.$route.meta.breadcrumb = [
            ...constants.DEVICES_ROUTE_META_BREADCRUMB
          ];
          if (this.device && this.device.sensors) {
            const gpsSensors = this.device["sensors"].find(
              (e) => e.type.toLowerCase() === "gps"
            );
            this.isTripDetector = gpsSensors && gpsSensors.type ? true : false;
            const fuelSensors = this.device["sensors"].find(
              (e) => e.type.toLowerCase() === "fuel"
            );
            this.isFuelTheft = fuelSensors && fuelSensors.type ? true : false;
          }
          this.isNewTabs = true;

          if (
            ((this.$route.name === "edit-device" ||
              this.$route.name === "add-new-device-id") &&
              this.checkAbility({
                action: constants.PERMISSIONS_ACTION.UPDATE,
                subject: constants.PERMISSIONS_MODEL.DEVICES
              })) ||
            (this.checkAbility({
              action: constants.PERMISSIONS_ACTION.ADD,
              subject: constants.PERMISSIONS_MODEL.DEVICES
            }) &&
              response.data.isDeviceEditable)
          ) {
            this.isEditable = true;

            this.$route.meta.breadcrumb.push(
              ...[
                {
                  to: { name: "edit-device", params: { id: response.data.id } },
                  text: response.data.device_name
                }
                // {
                //   text: "Edit",
                //   active: true
                // }
              ]
            );
          } else {
            this.$route.meta.breadcrumb.push({
              text: response.data.device_name
            });
            this.isEditable = false;
          }

          if (
            this.$route.name == "view-device" &&
            this.$route.params &&
            this.$route.params.id
          ) {
            this.isEditable = false;
          }
          this.isViewOnly =
            this.$route.name === "view-device" ||
            (this.device && this.device.isDeviceEditable == false)
              ? true
              : false;
          // this.oldDeviceOwnerId = response.data.owner_account_id;
          //  this.deviceOwnerId = response.data.owner_account_id;
          this.initialDevice = Object.assign({}, this.device);
          this.allocationFlag = true;
          this.OldDeviceData = Object.assign({}, this.device);
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.showLoading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    deviceFun(data) {
      if (
        this.$route.name === "add-new-device" ||
        (this.$route.name === "add-new-device-id" && this.$route.query.tab == 0)
      ) {
        this.isDisabled();
      } else if (
        this.$route.name === "add-new-device-id" &&
        this.$route.query.tab == 1
      ) {
        this.nextDisabled = false;
      }
      this.isUpdateCall = false;
      this.device = data;
      if (data && data.manufacturerIdOld) {
        this.manufacturerIdOld = data.manufacturerIdOld;
      }
      this.isReset = false;
      if (this.device && this.device.default_protocol) {
        this.protocolName = this.device.default_protocol;
      }
    },
    async saveCommands(tab) {
      try {
        if (this.isTabClick) {
          return true;
        }
        const device = this.deviceData;

        this.showLoading = true;
        device.commands = this.DeviceCommandsData || [];

        device.id = device.id || device.deviceId;

        this.refreshSensorsModal = new Date() + "";

        const commandData = device.commands.map((e) => {
          const commandObj = {
            code: e.code,
            // command: e.command,
            description: e.description,
            enable: e.enable,
            params: e.params
          };
          return commandObj;
        });
        let response = await new DeviceService().updateDeviceParamaters({
          id: device.id,
          commands: { _arr: commandData }
        });
        this.showLoading = false;
        if (response && response.data) {
          this.device.commands = [...this.DeviceCommandsData];
          this.OldDeviceData["commands"] = [...this.DeviceCommandsData];
          let successMsg = "added";
          if (tab === "edit" || tab == "update" || tab === "clone") {
            successMsg = this.$t("device.Updated");
          } else if (tab === "remove") {
            successMsg = this.$t("device.Removed");
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("device.CommandSaveMessage", {
                successMsg: successMsg ? successMsg : ""
              }),
              text: "",
              icon: "InfoIcon",
              variant: "success"
            }
          });
          return true;
        } else if (response && response.error && response.error.message) {
          this.resetForm();
          this.isReset = true;
          this.showLoading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.showLoading = false;
        this.resetForm();
        this.isReset = true;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async saveFiltersData(data) {
      this.filtersData = data;
    },
    async saveSensors(tab, isSensorOrdering, sensorType) {
      try {
        if (this.isTabClick) {
          return true;
        }
        const device = this.deviceData;

        this.showLoading = true;
        device.sensors = this.DeviceSensorData || [];

        device.id = device.id || device.deviceId;

        this.refreshSensorsModal = new Date() + "";

        const sensors = this.DeviceSensorData.map((s) => {
          const sensorsObj = {
            description: s.description,
            fields: s.fields,
            name: s.name,
            type: s.type,
            visible: s.visible,
            ...(s.type === "VIDEO_STREAM" && {
              streamUrlData: s?.streamUrlData || {}
            })
          };
          return sensorsObj;
        });

        if (!this.createGraph(sensors)) {
          return false;
        }
        let response;
        if (
          isSensorOrdering &&
          this.checkAbility({
            action: constants.PERMISSIONS_ACTION.CARD_ORDERING,
            subject: constants.PERMISSIONS_MODEL.DEVICES
          })
        ) {
          response = await new DeviceService().updateDeviceParametersReOrder({
            id: device.id,
            sensors: { _arr: sensors }
          });
        } else {
          response = await new DeviceService().updateDeviceParamaters({
            id: this.device.id,
            sensors: { _arr: sensors }
          });
        }
        if (response && response.data) {
          const videoSensor = sensors.find(
            (item) => item.type == "VIDEO_STREAM"
          );
          if (
            videoSensor &&
            videoSensor.fields &&
            videoSensor.fields.clipping_time?.value
          ) {
            const clippingResponse = await new DataService().updateClippingTime(
              {
                device_id: this.device.id,
                sensor: videoSensor.name,
                clipping_time: videoSensor.fields.clipping_time?.value
              }
            );
          }
          this.device.sensors = [...this.DeviceSensorData];
          this.OldDeviceData["sensors"] = [...this.DeviceSensorData];
          const gpsSensors = device["sensors"].find(
            (e) => e.type.toLowerCase() === "gps"
          );
          this.isTripDetector = gpsSensors && gpsSensors.type ? true : false;
          const fuelSensors = device["sensors"].find(
            (e) => e.type.toLowerCase() === "fuel"
          );
          this.isFuelTheft = fuelSensors && fuelSensors.type ? true : false;

          this.showLoading = false;
          let successMsg;
          let isRemoved = false;
          if (tab === "edit" || tab === "clone") {
            successMsg = this.$t("device.Updated");
          } else if (tab === "remove") {
            successMsg = this.$t("device.Removed");
            isRemoved = true;
          } else {
            successMsg = this.$t("device.Added");
          }
          if (isSensorOrdering) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("device.SensorOrdering"),
                text: "",
                icon: "InfoIcon",
                variant: "success"
              }
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("device.SensorSaveMessage", {
                  successMsg: successMsg ? successMsg : ""
                }),
                text: "",
                icon: "InfoIcon",
                variant: "success"
              }
            });
          }
          setTimeout(() => {
            if (sensorType && sensorType.toLowerCase() === "fuel") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t("device.FuelConfigMessage"),
                  text: "",
                  icon: "InfoIcon",
                  variant: "success"
                }
              });
            }
            if (sensorType && sensorType.toLowerCase() === "gps") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t("device.TripDetectorConfigMessage"),
                  text: "",
                  icon: "InfoIcon",
                  variant: "success"
                }
              });
            }
            return true;
          }, 1000);
          return true;
        } else if (response && response.error && response.error.message) {
          this.showLoading = false;
          this.resetForm();
          this.isReset = true;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          return false;
        }
      } catch (err) {
        this.showLoading = false;
        this.resetForm();
        this.isReset = true;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
      return false;
    },

    onClose() {
      this.$bvModal.hide("modal-sm-update-model");
      this.resetForm();
    },

    async createDevice() {
      if (this.isTabClick) {
        return true;
      }
      if (
        this.$route.name !== "add-new-device" &&
        this.OldDeviceData.model &&
        this.OldDeviceData.model !== this.device.model
      ) {
        this.customData = {
          icon: "XIcon",
          msgTxt: this.$t("device.ConfirmDeviceModelUpdateMsgTxt"),
          isQuite: false,
          title: this.$t("device.ConfirmDeviceModelUpdateMsgTitle"),
          btnTxt: this.$t("device.ConfirmDeviceModelUpdateBtnTxt"),
          lastTxt: this.$t("device.ConfirmDeviceModelUpdateLastTxt"),
          btnTxtRemove: this.$t("device.ConfirmDeviceModelUpdateBtnTxtRemove")
        };
        this.$bvModal.show("modal-sm-update-model");
      } else {
        const result = await this.createDeviceData(false);
        return result;
      }
    },
    // async updateDeviceData() {
    //   const result = await this.createDeviceData(true);
    //   return result;
    // },

    async createDeviceData(isUpdateModel) {
      this.isNewTabs = false;

      if (this.isUpdateCall) {
        return;
      }

      this.$bvModal.hide("modal-sm-update-model");
      await this.validationForm();
      this.isUpdateCall = true;
      let hasErr = "";
      let leading_zero = 0;
      const me = this;
      if (
        !this.device.SIMNumber1 &&
        this.device &&
        this.device.sim_cards &&
        this.device.sim_cards._arr.length == 1
      ) {
        this.device.SIMNumber1 = this.device.sim_cards._arr[0].sim_number;
        this.device.SIMOperator1 = this.device.sim_cards._arr[0].sim_operator;
      }
      if (
        !this.device.SIMNumber2 &&
        this.device &&
        this.device.sim_cards &&
        this.device.sim_cards._arr.length == 2
      ) {
        this.device.SIMNumber2 = this.device.sim_cards._arr[1].sim_number;
        this.device.SIMOperator2 = this.device.sim_cards._arr[1].sim_operator;
      }
      this.device.SIMNumber1 =
        this.device.SIMNumber1 && String(this.device.SIMNumber1);
      for (let c of this.device.SIMNumber1) {
        if (c !== "0") {
          break;
        }
        leading_zero++;
      }
      this.device.SIMNumber1 = this.device.SIMNumber1.slice(
        leading_zero,
        this.device.SIMNumber1.length
      );
      if (this.device.SIMNumber2) {
        this.device.SIMNumber2 =
          this.device.SIMNumber2 && String(this.device.SIMNumber2);
        leading_zero = 0;
        for (let c of this.device.SIMNumber2) {
          if (c !== "0") {
            break;
          }
          leading_zero++;
        }
        this.device.SIMNumber2 = this.device.SIMNumber2.slice(
          leading_zero,
          this.device.SIMNumber2.length
        );
        this.device.SIMNumber1 = this.device.SIMNumber1.replace(/^0+/, "");
        this.device.SIMNumber2 = this.device.SIMNumber2.replace(/^0+/, "");
        if (this.device.SIMNumber1 == this.device.SIMNumber2) {
          hasErr = this.$t("errors.SIMNumberErr");
          this.hasErr = hasErr;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: hasErr,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          return hasErr;
        }
      }
      try {
        // if (
        //   JSON.stringify(this.device) === JSON.stringify(this.initialDevice)
        // ) {
        //   this.$toast({
        //     component: ToastificationContent,
        //     props: {
        //       title: this.identifierError
        //         ? "Please Change Identifier details"
        //         : "Please change sim card details",
        //       icon: "InfoIcon",
        //       variant: "danger"
        //     }
        //   });
        //   return;
        // }
        this.showLoading = true;
        this.device.uniqueIdentifier =
          this.device.uniqueIdentifier || this.device.identifier;
        for (let c of this.device.uniqueIdentifier) {
          if (c == " ") {
            this.uniqueHandlError = true;
            this.showLoading = false;
            return false;
          }
        }
        const isValidateLabels =
          this.device.labels &&
          this.device.labels.every(
            (item) => typeof item === "object" && item !== null
          );

        if (isValidateLabels) {
          // console.log(this.device.labels);
          const me = this;
          me.device.labels =
            me.device &&
            me.device.labels &&
            me.device.labels.map((label) => label && label.name);
          me.device.labels =
            me.device.labels &&
            me.device.labels.filter((label) => label !== "" || label !== null);
        }
        this.device.labels =
          me.device.labels &&
          this.device.labels.filter((label) => label !== "" || label !== null);
        let data = {
          timezone: this.device.timezone || "UTC",
          device_name: this.device.deviceName,
          version: this.device.version,
          identifier: this.device.uniqueIdentifier,
          status: this.device.status,
          labels: this.device.labels,
          model_id: this.device.model,
          password: this.device.password || ""
        };
        let sim_cards = [
          {
            sim_number: this.device.SIMNumber1,
            sim_operator: this.device.SIMOperator1
          }
        ];
        if (this.device.SIMNumber2) {
          sim_cards.push({
            sim_number: this.device.SIMNumber2,
            sim_operator: this.device.SIMOperator2
          });
        }

        data.sim_cards = JSON.stringify(sim_cards);
        if (this.device.id) {
          data.id = this.device.id;
        }

        if (isUpdateModel) {
          const sensors =
            this.updateDeviceModel &&
            this.updateDeviceModel.sensors &&
            this.updateDeviceModel.sensors._arr
              ? this.updateDeviceModel.sensors._arr.length
                ? this.updateDeviceModel.sensors._arr
                : []
              : this.updateDeviceModel.sensors || [];
          data.sensors = { _arr: sensors };

          data.commands = { _arr: [] };
          const gpsSensors = sensors.find(
            (e) => e.type.toLowerCase() === "gps"
          );
          const isTripDetector = gpsSensors && gpsSensors.type ? true : false;
          const fuelSensors = sensors.find(
            (e) => e.type.toLowerCase() === "fuel"
          );
          const isFuelTheft = fuelSensors && fuelSensors.type ? true : false;
          data.config = { trip_detector: {}, fuel_theft: {}, fuel_refill: {} };
          if (isTripDetector) {
            data.config.trip_detector = constants.TRIP_DETECTOR_DEFAULT_VALUE;
          }
          if (isFuelTheft) {
            data.config.fuel_theft =
              constants.FUEL_THEFT_DEFAULT_VALUE_NEW.fuel_theft;
            data.config.fuel_refill =
              constants.FUEL_THEFT_DEFAULT_VALUE_NEW.fuel_refill;
          }
        }
        let response =
          this.$route.params.id || data.id
            ? await new DeviceService().updateDevice({
                id: this.$route.params.id || data.id,
                ...data
              })
            : await new DeviceService().createDevice({
                ...data
              });
        this.showLoading = false;
        if (response && response.data) {
          this.isUpdateCall = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$route.params.id
                ? this.$t("device.DeviceUpdatedSuccessfully")
                : this.$t("device.SubmittedSuccessfully"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          // this.$router.push({ name: "devices-list" });

          if (!response.data.deviceRes) {
            await this.getOneDevice(this.$route.params.id);
          } else {
            await this.parserModelData({
              id: response.data.deviceRes.model_id
            });
            this.device = response.data.deviceRes;
            this.refreshSensorsModal = new Date() + "";
            this.deviceData = this.device;

            this.isNewTabs = false;
            if (response.data.deviceRes && response.data.deviceRes.sensors) {
              const gpsSensors = response.data.deviceRes["sensors"].find(
                (e) => e.type.toLowerCase() === "gps"
              );
              this.isTripDetector =
                gpsSensors && gpsSensors.type ? true : false;
              const fuelSensors = response.data.deviceRes["sensors"].find(
                (e) => e.type.toLowerCase() === "fuel"
              );
              this.isFuelTheft = fuelSensors && fuelSensors.type ? true : false;
            }
          }

          this.nextDisabled = false;
          this.initialDevice = Object.assign({}, this.device);
          this.deviceId = this.device.id;
          this.DeviceSensorData = this.device.sensors || [];
          this.DeviceCommandsData = this.device.commands || [];
          this.protocolName = this.device.default_protocol;
          this.isNewTabs = true;
          this.tripDetector =
            this.device.config && this.device.config.trip_detector;
          this.fuelTheftData =
            this.device.config && this.device.config.fuel_theft;
          this.fuelRefillData =
            this.device.config && this.device.config.fuel_refill;

          this.showLoading = false;

          if (this.$route.name === "add-new-device-id" && isUpdateModel) {
            this.activeTab = 1;

            this.$refs.formWizard.nextTab();
            return true;
          }
          return true;
        } else if (response && response.error && response.error.message) {
          this.isUpdateCall = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          this.identifierError =
            response.error.code == "POLY_ERR_DEVICE_ALREADY_EXIST"
              ? true
              : false;
          this.simCartError =
            response.error.code != "POLY_ERR_DEVICE_ALREADY_EXIST"
              ? true
              : false;
        } else {
          this.isUpdateCall = false;
        }

        this.initialDevice = Object.assign({}, this.device);
        this.OldDeviceData = Object.assign({}, this.device);
        return false;
      } catch (err) {
        this.isUpdateCall = false;
        this.showLoading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        return false;
      }
    },

    resetPrev() {
      //  this.deviceOwnerId = this.oldDeviceOwnerId;
    },
    activeTripDetector(flag) {
      let isTrue = false;
      if (this.isTripDetector !== flag) {
        isTrue = true;
      }
      this.isTripDetector = flag;
      if (isTrue) {
        setTimeout(() => {
          const nextIndex =
            this.$route.query &&
            (Number(this.$route.query.tab) ||
              Number(this.$route.query.tab) == 0)
              ? Number(this.$route.query.tab)
              : 0;
          if (this.$refs.formWizard) {
            this.$refs.formWizard.changeTab(this.activeTab, nextIndex);
          }
          if (this.isFormTab) {
            this.activeTab = nextIndex;
          }
        }, 200);
      }
    },
    activeFuelTheft(flag) {
      let isTrue = false;
      if (this.isFuelTheft !== flag) {
        isTrue = true;
      }
      this.isFuelTheft = flag;
      if (isTrue) {
        setTimeout(() => {
          const nextIndex =
            this.$route.query &&
            (Number(this.$route.query.tab) ||
              Number(this.$route.query.tab) == 0)
              ? Number(this.$route.query.tab)
              : 0;
          if (this.$refs.formWizard) {
            this.$refs.formWizard.changeTab(this.activeTab, nextIndex);
          }
          if (this.isFormTab) {
            this.activeTab = nextIndex;
          }
        }, 200);
      }
    },
    backPage() {
      // console.log(this.prevRoute);
      this.$router.push({
        name: this.prevRoute.name,
        query: { page: this.prevRoute.query.page }
      });
      return;
      if (this.prevRoute && this.prevRoute.name === "unit-list") {
      } else {
        this.$router.go(-1);
      }
    },
    onCloseModel() {
      this.$bvModal.hide("is-device-allocated-unit");
    },
    updateModel(model) {
      this.updateDeviceModel = model;
    },
    async changeNxtBtn1(e, r) {
      console.log("dcx");
    },
    async changeNxtBtn(prevIndex, index) {
      const nextIndex = index;
      if (
        this.$refs.formWizard &&
        this.$refs.formWizard.tabs &&
        this.$refs.formWizard.tabs.length
      ) {
        this.currentActivePage =
          this.$refs.formWizard.tabs[nextIndex] &&
          this.$refs.formWizard.tabs[nextIndex].title;
      } else {
        nextIndex = Number(this.$route.query.tab) || nextIndex;
      }
      if (nextIndex === 0) {
        this.isDisabled();
        this.nextBtn = this.$t("saveAndConfigure");
      }
      if (nextIndex === 1) {
        this.nextBtn = this.$t("device.Next");
      } else {
        this.nextBtn = this.$t("device.SaveNext");
      }
      this.activeTab = nextIndex;
      if (this.$route.name === "add-new-device") {
        this.$router.push({
          name: "add-new-device-id",
          params: { id: this.device.id },
          query: { tab: 1 }
        });
        return true;
      }
      this.$router.replace({ query: { tab: nextIndex } }).catch((e) => {});
    },
    handleTabChange(to, from) {
      this.activeTab = to;

      this.$router.replace({ query: { tab: this.activeTab } }).catch((e) => {});
    },
    gotoRoleList() {},
    DeviceSensorFun(sensors) {
      this.DeviceSensorData = sensors;
    },
    DeviceCommandFun(commands) {
      this.DeviceCommandsData = commands;
      this.device.commands = commands;
    },
    async getChildAccounts(filterAccount) {
      try {
        this.showLoading = true;
        let response = await new AccountService().getChildAccounts({
          isChild: true,
          filterAccount: filterAccount || undefined,
          page: this.currentPage,
          page_size: parseInt(this.perPage)
        });
        this.showLoading = false;
        if (response && response.data) {
          if (this.$route.params.id) {
            function recursive(data) {
              if (data && data.type.toUpperCase() == "CONSUMER") return true;

              if (data && data.children) {
                for (let i = 0; i < data.children.length; i++) {
                  if (recursive(data.children[i])) {
                    // delete data.children[i];
                    data.children[i]["isDisabled"] = true;
                  }
                }
              }
              return false;
            }
            recursive(response.data[0]);
            this.getAllAccountOptions = response.data;
            this.isDeviceReallocation = true;
          } else {
            return;
          }
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    redirectOnUnitCreation() {
      this.$router.push({ name: "unit-creation" });
    },
    redirectOnDeviceHome() {
      this.$router.push({ name: "devices-list" });
    },
    SkipStep() {
      this.$refs.formWizard.changeTab(
        this.activeTab,
        Number(this.activeTab) + 1
      );
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    },

    tripDetectorPropFun(data) {
      this.tripDetector = data;
    },
    async saveTripDetector() {
      try {
        if (this.isTabClick) {
          return true;
        }
        if (this.$route.name !== "edit-device") {
          await this.validationFormTripDetector();
        }

        this.showLoading = true;
        const device = this.device;
        if (!device.config) {
          device.config = {};
        }
        device.config.trip_detector = this.tripDetector;
        device.id = device.id || device.deviceId;
        if (!this.device.config) {
          this.device.config = {};
        }
        const tripDetectorObj = {
          id: device.id,
          config: {
            fuel_config: device.config.fuel_config,
            trip_detector: device.config.trip_detector
          }
        };
        let response = await new DeviceService().updateDeviceParamaters(
          tripDetectorObj
        );
        this.showLoading = false;
        if (response && response.data) {
          this.device.config.trip_detector = this.tripDetector;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("device.TripDetectorSubmittedSuccessFully"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          return true;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          return false;
        }
      } catch (err) {
        this.showLoading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    FuelTheftPropFun(data) {
      this.fuelTheftData = data && data.fuel_theft;
      this.fuelRefillData = data && data.fuel_refill;
    },
    async saveFuelTheft() {
      try {
        if (this.isTabClick) {
          return true;
        }
        if (this.$route.name !== "edit-device") {
          await this.deviceFuelTheftValidationForm();
        }

        const device = this.device;
        if (!device.config) {
          device.config = {};
        }

        device.config.fuel_theft = {
          enable: this.fuelTheftData.enable,
          km_per_liter: Number(this.fuelTheftData.km_per_liter),
          hour_per_liter: Number(this.fuelTheftData.hour_per_liter),
          min_theft: Number(this.fuelTheftData.min_theft),
          observe_time: Number(this.fuelTheftData.observe_time)
        };

        device.config.fuel_refill = {
          enable: this.fuelRefillData.enable || true,
          min_refill: Number(this.fuelRefillData.min_refill),
          observe_time: Number(this.fuelRefillData.observe_time),
          min_change: Number(this.fuelRefillData.min_change)
        };

        // if (
        //   this.FuelTheft &&
        //   Number(this.FuelTheft.max_threshold) <
        //     Number(this.FuelTheft.min_threshold)
        // ) {
        //   this.$toast({
        //     component: ToastificationContent,
        //     props: {
        //       title: this.$t("device.fuelTheft.MinMaXError"),
        //       icon: "InfoIcon",
        //       variant: "danger"
        //     }
        //   });
        //   return;
        // }
        this.showLoading = true;
        device.id = device.id || device.deviceId;
        if (!this.device.config) {
          this.device.config = {};
        }

        const fuelTheftObj = {
          id: device.id,
          config: {
            fuel_theft: device.config.fuel_theft,
            fuel_refill: device.config.fuel_refill,
            trip_detector: device.config.trip_detector
          }
        };
        let response = await new DeviceService().updateDeviceParamaters(
          fuelTheftObj
        );
        this.showLoading = false;
        if (response && response.data) {
          this.device.config.fuel_config = this.FuelTheft;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("device.fuelTheft.FuelTheftSubmittedSuccessFully"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          return true;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          return false;
        }
      } catch (err) {
        this.showLoading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async saveFilter(tab) {
      try {
        if (this.$route.name !== "edit-device") {
          await this.validationFormFilter();
        }
        const device = this.deviceData;

        this.showLoading = true;

        device.id = device.id || device.deviceId;

        let response = await new DeviceService().saveFilters({
          id: device.id,
          filters: this.filtersData
        });
        this.showLoading = false;
        if (response && response.data) {
          let successMsg = "added";
          if (tab === "edit" || tab == "update" || tab === "clone") {
            successMsg = this.$t("device.Updated");
          } else if (tab === "remove") {
            successMsg = this.$t("device.Removed");
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("device.filters.saveFilterSuccessFully", {
                successMsg: successMsg ? successMsg : ""
              }),
              text: "",
              icon: "InfoIcon",
              variant: "success"
            }
          });
          return true;
        } else if (response && response.error && response.error.message) {
          this.showLoading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.showLoading = false;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.map-outer {
  width: 100%;
}
.wizard-custom::after {
  display: none;
}
.mr-11 {
  margin-right: 11px;
}
.wizard-back-unit {
  position: absolute;
  z-index: 9;
  right: 5px;
  top: 5px;
  cursor: pointer;
}
.btn-disabled {
  color: gray;
}

.allocation-wrap {
  flex-wrap: wrap;
  .btn.btn-outline-secondary {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .FuelTheft-w {
    padding: 10px !important;
  }
}
</style>
<style lang="scss">
@media only screen and (max-width: 600px) {
  .nav-tabs .nav-link {
    font-size: 15px !important;
    padding: 0.51rem 0.5rem !important;
  }
}
.card-body-p {
  .card-body {
    padding: 0 !important;
  }
}
</style>
<style>
/* .disabled-next .wizard-footer-right .wizard-btn {
  pointer-events: none !important;
  opacity: 0.4 !important;
} */
</style>
