<template>
  <div>
    <b-card no-body class="mb-0 alert-notification simlist-view-tab">
      <div class="table-responsive" v-if="show">
        <b-skeleton-table
          v-if="show"
          :rows="this.currentPerPage || 10"
          :columns="1"
          :table-props="{ bordered: true, striped: true }"
        />
      </div>
      <b-table
        responsive
        show-empty
        sticky-header
        :items="items"
        :fields="tableColumns"
        @row-selected="onRowSelected"
        select-mode="multiple"
        ref="table"
        v-else
      >
        <template #cell(expiry_date)="row">
          <span>
            {{ DateTimeConvert(row.item.expiry_date, constants.DATE_FORMAT) }}
          </span>
        </template>
        <template #cell(recharge_date)="row">
          <span>
            {{ DateTimeConvert(row.item.recharge_date, constants.DATE_FORMAT) }}
          </span>
        </template>
        <template #cell(ACTIONS)="row">
          <div class="text-left min-80 d-flex justify-content-end">
            <span>
              <b-link @click="openEditHistory(row)">
                <feather-icon
                  v-b-tooltip.hover.top="$t('tooTip.update')"
                  icon="EditIcon"
                  class="text-primary action-icon cursor-pointer"
                  size="18"
                />
              </b-link>
            </span>

            <span>
              <feather-icon
                v-b-tooltip.hover.top="$t('tooTip.delete')"
                icon="Trash2Icon"
                class="text-danger action-icon"
                size="18"
                v-b-modal.modal-sm-remove
                @click="deleteRecharge(row.item)"
                v-if="
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.DELETE,
                    subject: constants.PERMISSIONS_MODEL.REPORTS
                  })
                "
              />
            </span>
          </div>
        </template>
      </b-table>
      <div class="mx-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            &nbsp;
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="totalAlert > 0"
              v-model="currentPage"
              :total-rows="totalAlert"
              :per-page="currentPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="handlePageChange"
              :no-paging-nav="false"
              :active-class="activeClass"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <EditRecharge
      ref="AddRecharge1"
      :refreshed="refreshed"
      :selectedRow="selectedRow"
      :rechargeModelName="rechargeModelName"
    />
    <AreYouSureModal
      :data="{
        ...deleteData,
        modelName: modelName
      }"
      :onClose="onClose"
      :removedUser="removeRecharge"
    />
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BPagination,
  VBTooltip,
  BFormCheckbox,
  BSkeletonTable
} from "bootstrap-vue";
import EditRecharge from "./EditRecharge.vue";
import RemoveAlerts from "@/layouts/components/RemoveAlerts.vue";
import UnitService from "@/libs/api/unit-service";
import Ripple from "vue-ripple-directive";
import Loader from "@/layouts/components/Loader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DynamicBadge from "@/layouts/components/DynamicBadge.vue";
import Badge from "@/layouts/components/Badge.vue";
import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import RechargeIcon from "@/assets/images/icons/rechargeIcon.svg";
import DeviceService from "@/libs/api/device-service";
export default {
  setup() {
    const resolveFilterBadgeColor = (filter) => {
      filter = filter && filter.toUpperCase();
      const status = {
        ACTIVE: "light-success",
        INACTIVE: "light-warning",
        DAMAGED: "light-danger",
        BLOCKED: "light-secondary"
      };

      const selectedStatus =
        filter && status[filter] ? status[filter] : status["ACTIVE"];

      return selectedStatus;
    };
    return {
      resolveFilterBadgeColor
    };
  },
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    Loader,
    BLink,
    BBadge,
    BPagination,
    RemoveAlerts,
    VBTooltip,
    BSkeletonTable,
    DynamicBadge,
    Badge,
    AreYouSureModal,
    BFormCheckbox,
    RechargeIcon,
    EditRecharge
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },
  data() {
    return {
      isHide: true,
      rechargeModelName: "edit_recharge_modal_1",
      modelName: "delete",
      tableColumns: [
        {
          key: "recharge_date",
          tdClass: "text-left ",
          thClass: "text-left ",
          label: this.$t("sim.RechargeDate")
        },
        {
          key: "plan",
          tdClass: "text-left ",
          thClass: "text-left ",
          label: this.$t("sim.Plan")
        },
        {
          key: "amount",
          tdClass: "text-left ",
          thClass: "text-left ",
          label: this.$t("sim.Amount")
        },
        {
          key: "expiry_date",
          tdClass: "text-left",
          thClass: "text-left",
          label: this.$t("sim.DueDate")
        },

        {
          key: "ACTIONS",
          tdClass: "text-right",
          thClass: "text-right",
          label: this.$t("sim.Actions")
        }
      ],
      totalAlert: 0,
      currentPerPage: this.nearestPage(
        ["10", "20", "30"],
        parseInt(window.innerHeight / 71)
      ),

      activeClass: "active",
      items: [],
      deleteData: {},
      show: false,
      notifications: [],
      totalNotifications: 0,
      defaultBadgeShow: 2,
      timeoutId: null,
      items: [
        { id: 1, name: "Item 1", selected: false },
        { id: 2, name: "Item 2", selected: false },
        { id: 3, name: "Item 3", selected: false }
      ],
      selectedRow: {}
    };
  },
  props: [
    "searchFiled",
    "groupName",
    "perPage",
    "getSelectedAlert",
    "alertId",
    "isGroupEntity",
    "isAllowChild",
    "selectedAccount"
  ],

  watch: {
    selectedAccount(val) {
      this.currentPage = 1;
      this.debounceGetAllRecharge();
    },
    perPage(val) {
      if (!this.perPage) {
        this.currentPerPage = 10;
      }
      this.currentPerPage = val;
      const query = Object.assign({}, this.$route.query);

      query.perPage = val;

      this.$router.replace({ query }).catch(() => {});

      this.debounceGetAllRecharge();
    },
    currentPage() {},
    searchFiled(newVal, oldVal) {
      this.currentPage = 1;
      this.filter = newVal;
      this.debounceGetAllRecharge();
    },
    isAllowChild() {
      this.debounceGetAllRecharge();
    }
  },
  computed: {
    allSelected() {
      return this.items.every((item) => item.selected);
    }
  },
  methods: {
    async refreshed() {
      this.selectedRow = {};
      this.$bvModal.hide("edit_recharge_modal_1");
      this.getRecharges();
    },
    deleteRecharge(props) {
      this.modelName = "delete";

      this.deleteData = {
        name: props.plan,
        id: props.id
      };
      this.customData = null;
      setTimeout((e) => {
        this.$bvModal.show(this.modelName);
      }, 100);
    },
    openEditHistory(row) {
      this.selectedRow = row.item;

      this.$bvModal.show("edit_recharge_modal_1");
    },

    debounceGetAllRecharge() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getRecharges();
      }, 300); // Adjust the delay as necessary
    },
    async getRecharges() {
      try {
        this.show = true;
        const me = this;
        if (!this.filter || !this.filter.value) {
          if (this.$route.query.filter) {
            this.filter = {
              field: "amount",
              operator: "ilike",
              value: this.$route.query.filter
            };
          } else {
            this.filter = {};
          }
        } else if (this.filter && this.filter.value) {
          if (this.$route.query.filter) {
            this.filter = {
              field: "amount",
              operator: "ilike",
              value: this.filter.value
            };
          } else {
            this.filter = {};
          }
        }
        const query = Object.assign({}, this.$route.query);
        let requestData = {
          page: query.page ? Number(query.page) : this.currentPage,
          page_size: query.pageSize
            ? Number(query.pageSize)
            : parseInt(this.currentPerPage),
          sim_id: this.$route.params.id,
          filters:
            this.filter && Object.keys(this.filter).length > 0
              ? [this.filter]
              : []
        };

        let response = await new DeviceService().getRecharges(requestData);

        if (response && response.data) {
          this.show = false;
          this.items = response.data.list || [];
          this.items = this.items.map((u) => {
            return u;
          });

          this.totalAlert =
            (response.data.pagination &&
              response.data.pagination.total_records) ||
            0;
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
      this.showLabelSize();
    },
    async removeRecharge(props) {
      try {
        this.showLoading = true;
        let req = { recharge_id: props.id };

        let response = await new DeviceService().removeRecharge(req);
        this.showLoading = false;
        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("sim.DeletedRechargeSuccessfully"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.$bvModal.hide(this.modelName);
          this.getRecharges();
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.showLoading = false;
      }
    },
    toggleAllSelection() {
      const newValue = !this.allSelected;
      this.items.forEach((item) => {
        item.selected = newValue;
      });
    },
    updateSelected(item) {
      // Handle individual checkbox changes if needed
    },
    onRowSelected(selectedRows) {
      // console.log("Selected rows:", selectedRows);
    },
    showLabelSize() {
      const parentElement = document.querySelector(".parent");
      if (parentElement) {
        const parentWidth = parentElement.offsetWidth;
        this.defaultBadgeShow = Math.floor(parentWidth / 71);
      }
    },
    onClose() {
      this.$bvModal.hide(this.modelName);
      this.debounceGetAllRecharge();
    },
    close(data) {},
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.id === this.alertId) return "table-primary-light";
    },
    handleRowClick(e) {
      if (this.$route.name === "notification-create") {
        this.getSelectedAlert(e);
      }
    },
    async removedAlert(params) {
      try {
        this.show = true;
        if (!params || !params.id) {
          this.$toast({
            component: ToastificationContent,
            props: {
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          return false;
        }
        let response = await new UnitService().removeAlert({
          id: params.id,
          account_id: this.$route.query.account_id
        });
        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              text: this.$t("alert.RemoveSuccessfully"),
              icon: "EditIcon",
              variant: "success"
            }
          });

          this.onClose();
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    updateUrl(e) {
      this.$router.push({
        query: { ...this.$route.query, [e.key]: e.value }
      });
    },
    handlePageChange(newPage) {
      this.updateQueryParam();
    },
    updateQueryParam() {
      const query = {
        ...this.$route.query,
        page: this.currentPage.toString()
      };
      if (this.filter && this.filter.value) {
        query.filter = this.filter.value;
      }
      this.$router.replace({ query }).catch(() => {});
      this.debounceGetAllRecharge();
    },
    DateTimeConvert(date, format) {
      return this.formattedDateTime(date, format);
    }
  },

  mounted() {
    this.$nextTick(() => {
      const { page, filter, perPage } = this.$route.query;

      if (!page) {
        this.updateUrl({ key: "page", value: 1 });
      } else {
        this.currentPage = parseInt(page);
      }

      if (filter) {
        this.filter = filter;
      }

      if (perPage) {
        this.perPage = parseInt(perPage);
      }
      this.debounceGetAllRecharge();
    });

    if (this.isGroupEntity) {
      this.debounceGetAllRecharge();
    }
    window.addEventListener("resize", this.showLabelSize);
    // setTimeout(() => {
    //   this.isHide = false;
    // }, 2000);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.showLabelSize);
  }
};
</script>
<style lang="scss">
.alert-action-width {
  max-width: 130px;
  width: 130px;
  min-width: 130px;
}
.status-width {
  max-width: 110px;
  width: 110px;
  min-width: 110px;
  text-align: center;
}
.group-width-right {
  max-width: 200px;
  width: 200px;
  min-width: 200px;
  text-align: right !important;
}
.group-width {
  text-align: right;
}
.badge-group {
  height: 15px;
  padding: 1px 9px 1px 9px;
  font-size: 9px;
  line-height: 11px;
}
.ml-10 {
  margin-left: 10px;
}
.b-table-sticky-header {
  @supports (position: sticky) {
    .table.b-table > thead > tr > .table-b-table-default,
    .table.b-table > tbody > tr > .table-b-table-default,
    .table.b-table > tfoot > tr > .table-b-table-default {
      top: -1px !important;
    }
  }
}
.dark-layout .b-table-sticky-header {
  @supports (position: sticky) {
    .table.b-table > thead > tr > .table-b-table-default,
    .table.b-table > tbody > tr > .table-b-table-default,
    .table.b-table > tfoot > tr > .table-b-table-default {
      color: #d0d2d6 !important;
    }
  }
}
</style>
<style lang="scss">
.simlist-view-tab {
  .b-table-sticky-header {
    max-height: calc(100vh - 362px) !important;
    min-height: calc(100vh - 362px) !important;
    overflow-y: auto;
  }
  .table-responsive {
    max-height: calc(100vh - 362px) !important;
    min-height: calc(100vh - 362px) !important;
    overflow-y: auto;
  }
}
@media only screen and (max-width: 540px) {
  .simlist-view-tab {
    .b-table-sticky-header {
      max-height: calc(100vh - 522px) !important;
      min-height: calc(100vh - 522px) !important;
      overflow-y: auto;
    }
    .table-responsive {
      max-height: calc(100vh - 522px) !important;
      min-height: calc(100vh - 522px) !important;
      overflow-y: auto;
    }
  }
}
.Recharge-w {
  min-width: 316px !important;
}
</style>
