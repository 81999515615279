var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-device-align"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center mb-1"},[_c('div',[_c('h4',{staticClass:"card-title mb-0"},[_vm._v(_vm._s(_vm.$t("device.General")))])]),_c('b-button',{staticClass:"btn set-back-mobile v2-back",attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function (e) {
          _vm.$router.push({ name: 'devices-list' });
        }}},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"ArrowLeftIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t("back.back")))])],1)],1),_c('div',{staticClass:"d-create"},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":"Device Name","rules":{
            required: true,
            min: _vm.constants.MIN_DEVICE_NAME,
            max: _vm.constants.MAX_DEVICE_NAME
          }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('device.DeviceName*')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('device.DeviceName'),"name":"deviceName","disabled":!_vm.isEditable,"trim":"","state":_vm.getValidationState(validationContext)},on:{"input":function($event){return _vm.deviceFun(_vm.device)}},model:{value:(_vm.device.deviceName),callback:function ($$v) {_vm.$set(_vm.device, "deviceName", $$v)},expression:"device.deviceName"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0] ? _vm.handleError(validationContext.errors[0]) : "")+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":"Device Version","rules":{
            max: _vm.constants.MAX_DEVICE_VERSION
          }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('device.Version')}},[_c('b-form-input',{attrs:{"name":"deviceVersion","placeholder":_vm.$t('device.Version'),"disabled":!_vm.isEditable,"state":_vm.getValidationState(validationContext)},on:{"input":function($event){return _vm.deviceFun(_vm.device)}},model:{value:(_vm.device.version),callback:function ($$v) {_vm.$set(_vm.device, "version", $$v)},expression:"device.version"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0] ? _vm.handleError(validationContext.errors[0]) : "")+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('simAutoSelect',{attrs:{"name":"SIM number1","placeholder":_vm.$t('device.SIMNumber1'),"isEditable":false,"operatorKey":"SIMOperator1","simKey":"SIMNumber1","isRequired":"true"},on:{"setOperator":_vm.setOperator},model:{value:(_vm.device.SIMNumber1),callback:function ($$v) {_vm.$set(_vm.device, "SIMNumber1", $$v)},expression:"device.SIMNumber1"}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('validation-provider',{attrs:{"name":"SIM operator","rules":{
            required: true,
            min: _vm.constants.MIN_SIM_OPERATOR,
            max: _vm.constants.MAX_SIM_OPERATOR
          }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('device.SIMOperator*')}},[_c('b-form-input',{attrs:{"name":"SIM Operator1","placeholder":_vm.$t('device.SIMOperator1'),"disabled":!_vm.isEditable,"state":_vm.getValidationState(validationContext)},on:{"input":function($event){return _vm.deviceFun(_vm.device)}},model:{value:(_vm.device.SIMOperator1),callback:function ($$v) {_vm.$set(_vm.device, "SIMOperator1", $$v)},expression:"device.SIMOperator1"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0] ? _vm.handleError(validationContext.errors[0]) : "")+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('simAutoSelect',{attrs:{"name":"SIM number1","placeholder":_vm.$t('device.SIMNumber2'),"isEditable":false,"simKey":"SIMNumber2","isRequired":false,"operatorKey":"SIMOperator2"},on:{"setOperator":_vm.setOperator},model:{value:(_vm.device.SIMNumber2),callback:function ($$v) {_vm.$set(_vm.device, "SIMNumber2", $$v)},expression:"device.SIMNumber2"}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('validation-provider',{attrs:{"name":"SIM Operator","rules":{
            min: _vm.constants.MIN_SIM_OPERATOR,
            max: _vm.constants.MAX_SIM_OPERATOR
          }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('device.SIMOperator2')}},[_c('b-form-input',{attrs:{"name":"SIMOperator","disabled":!_vm.isEditable,"placeholder":_vm.$t('device.SIMOperator2'),"state":_vm.getValidationState(validationContext)},on:{"input":function($event){return _vm.deviceFun(_vm.device)}},model:{value:(_vm.device.SIMOperator2),callback:function ($$v) {_vm.$set(_vm.device, "SIMOperator2", $$v)},expression:"device.SIMOperator2"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0] ? _vm.handleError(validationContext.errors[0]) : "")+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Device Manufacture","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('device.Manufacture*'),"state":_vm.getValidationState(validationContext)}},[_c('v-select',{attrs:{"id":"vue-select","disabled":!_vm.isEditable,"placeholder":_vm.$t('device.SelectManufacture'),"options":_vm.manufacturers,"reduce":function (option) { return option.id; },"label":"title"},on:{"input":function($event){_vm.changedManufacture();
                _vm.deviceFun(_vm.device);}},model:{value:(_vm.manufacturerId),callback:function ($$v) {_vm.manufacturerId=$$v},expression:"manufacturerId"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0] ? _vm.handleError(validationContext.errors[0]) : "")+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Device Model","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('device.Model*'),"state":_vm.getValidationState(validationContext)}},[_c('v-select',{attrs:{"id":"vue-select","disabled":!_vm.isEditable,"placeholder":_vm.$t('device.SelectdeviceModel'),"options":_vm.modelOptions,"reduce":function (option) { return option.id; },"label":"title","menu-props":"auto"},on:{"input":function($event){return _vm.changedDeviceModel()}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                var title = ref.title;
                var model_icon_url = ref.model_icon_url;
return [_c('b-img',{attrs:{"src":model_icon_url,"height":"14px","width":"14px","alt":title}}),_c('span',{staticClass:"ml-50 text-body"},[_vm._v(_vm._s(title))])]}},{key:"selected-option",fn:function(ref){
                var model_icon_url = ref.model_icon_url;
                var title = ref.title;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-img',{attrs:{"src":model_icon_url,"height":"14px","width":"14px","alt":title}}),_c('span',{staticClass:"ml-50 text-body"},[_vm._v(_vm._s(title))])],1)]}}],null,true),model:{value:(_vm.deviceModel),callback:function ($$v) {_vm.deviceModel=$$v},expression:"deviceModel"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0] ? _vm.handleError(validationContext.errors[0]) : "")+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('device.Status*'),"state":_vm.getValidationState(validationContext)}},[_c('v-select',{attrs:{"id":"vue-select","disabled":!_vm.isEditable,"placeholder":_vm.$t('device.Selectdevicestatus'),"options":_vm.statusOption,"reduce":function (option) { return option.key; },"label":"label"},on:{"input":function($event){return _vm.deviceFun(_vm.device)}},model:{value:(_vm.device.status),callback:function ($$v) {_vm.$set(_vm.device, "status", $$v)},expression:"device.status"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0] ? _vm.handleError(validationContext.errors[0]) : "")+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":"Unique Identifier","rules":{
            required: true,
            min: _vm.constants.MIN_UNIQUE_IDENTIFIER,
            max: _vm.constants.MAX_UNIQUE_IDENTIFIER
          }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('device.UniqueIdentifier*')}},[_c('b-form-input',{attrs:{"name":"uniqueIdentifier","disabled":!_vm.isEditable,"placeholder":_vm.$t('device.UniqueIdentifier'),"state":_vm.getValidationState(validationContext)},on:{"input":function($event){return _vm.deviceFun(_vm.device)},"change":_vm.veriyUniueIdentifier},model:{value:(_vm.device.uniqueIdentifier),callback:function ($$v) {_vm.$set(_vm.device, "uniqueIdentifier", $$v)},expression:"device.uniqueIdentifier"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0] ? _vm.handleError(validationContext.errors[0]) : "")+" ")]),(_vm.uniqueHandlError)?_c('div',{staticStyle:{"margin-top":"10px"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t("device.UniqueIdentifierSapce")))])]):_vm._e()],1)]}}])})],1)],1),_c('b-row',[(
          _vm.checkAbility({
            action: _vm.constants.PERMISSIONS_ACTION.READ,
            subject: _vm.constants.PERMISSIONS_MODEL.LABELS
          })
        )?_c('b-col',{staticClass:"tag-select-disable",attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('unit.Labels'),"disabled":!_vm.isEditable}},[_c('TagMultiSelect',{class:!_vm.isEditable ? 'disabled' : '',on:{"input":function($event){return _vm.deviceFun(_vm.device)}},model:{value:(_vm.device.labels),callback:function ($$v) {_vm.$set(_vm.device, "labels", $$v)},expression:"device.labels"}})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('profile.TimeZone'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{staticClass:"is-valid timezone-input",attrs:{"state":_vm.getValidationState(validationContext),"label":_vm.$t('profile.TimeZone') + '*'}},[_c('v-select',{staticClass:"timesize",class:!_vm.isEditable ? 'disabled' : '',attrs:{"id":"vue-select","disabled":!_vm.isEditable,"options":_vm.timeZone.option,"reduce":function (option) { return option.value; },"label":"label"},on:{"input":function($event){return _vm.deviceFun(_vm.device)}},model:{value:(_vm.device.timezone),callback:function ($$v) {_vm.$set(_vm.device, "timezone", $$v)},expression:"device.timezone"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0] ? _vm.handleError(validationContext.errors[0]) : "")+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":"Device Password","rules":{
            max: _vm.constants.MAX_DEVICE_PASSWORD
          }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('device.Password')}},[_c('b-form-input',{attrs:{"name":"devicePassword","placeholder":_vm.$t('device.Password'),"disabled":!_vm.isEditable,"state":_vm.getValidationState(validationContext)},on:{"input":function($event){return _vm.deviceFun(_vm.device)}},model:{value:(_vm.device.password),callback:function ($$v) {_vm.$set(_vm.device, "password", $$v)},expression:"device.password"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0] ? _vm.handleError(validationContext.errors[0]) : "")+" ")])],1)]}}])})],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.protocol_info.port && _vm.deviceModel),expression:"protocol_info.port && deviceModel"}],attrs:{"md":"6"}},[_c('b-row',{staticClass:"d-flex justify-content-between mt-2"},[_c('b-col',{staticClass:"mb-1",attrs:{"md":"4","cols":"12"}},[_c('span',[_vm._v(_vm._s(_vm.$t("device.Protocol"))+" "+_vm._s(_vm.protocol_info.protocol))])]),_c('b-col',{staticClass:"mb-1",attrs:{"md":"4","cols":"12"}},[_c('span',[_vm._v(_vm._s(_vm.$t("device.ServerIP"))+" "+_vm._s(_vm.protocol_info.server_ip))])]),_c('b-col',{staticClass:"mb-1",attrs:{"md":"4","cols":"12"}},[_c('span',[_vm._v(_vm._s(_vm.$t("device.Port"))+" "+_vm._s(_vm.protocol_info.port))])])],1)],1)],1),_c('Loader',{attrs:{"show":_vm.showLoading}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }